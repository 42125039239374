<template>
    <div class="inter overflow">
      <div class="mask" v-if="isMaskVisible">
          <!-- 加载动画start -->
          <loadAction :loading="isLoad" />
          <!-- 加载动画end --> 
      </div>
      <div class="tablePage" ref='tablePage'>
          <el-table border height='100%'
              :data="defaultTable" style="width: 100%" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
              element-loading-background="transparent" :highlight-current-row="true" 
              :isPage="false">
              <el-table-column label="下载日期" prop="create_time" style="width: 15%;"></el-table-column>
              <el-table-column  label="下载个人群" prop="personal_download_num" style="width: 30%;"> </el-table-column>
              <el-table-column  label="下载企业群" prop="enterprise_download_num" style="width: 30%;"></el-table-column>
              <el-table-column label="操作" >
                  <template slot-scope="scope">
                      <label v-if="(scope.row.personal_download_num>0 || scope.row.enterprise_download_num>0) && scope.row.can_download" style="color:blue; cursor: pointer;" @click="handleDownload(scope.$index, scope.row)">再次下载</label>
                      <label v-if="(scope.row.personal_download_num>0 || scope.row.enterprise_download_num>0) && !scope.row.can_download" style="color:#666; cursor: pointer;" @click="handleDownload(scope.$index, scope.row)">再次下载</label>
                  </template>
              </el-table-column>
          </el-table> 
      </div>
      <!--下载弹窗 -->
      <el-dialog title="您正在重复下载" :visible.sync="dialogVisible" top="32vh">
        <div class="downDialog">
            <li>
                <p>保存类型：</p>
                <div class="downRadio">
                    <RadioGroup v-model="downParams.type">
                        <Radio :label="index+1" v-for="(item,index) in downDrop.dataType" :key="index">{{item}}
                        </Radio>
                    </RadioGroup>
                </div>
            </li>
            <li>
                <p class="fontweight baseFour downToast">温馨提示：下载过的群，重复下载不扣积分。</p>
            </li>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" @click="sureDownMethod">确定下载</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import { mapState } from 'vuex';
  import api from "../../http/api";
  import { nowDataMethod, guid } from '../../utils/index';
  import { MessageBox, Message, Radio, RadioGroup } from 'element-ui';
  import { downFile, downFileExt, appendText, isDownAddress, addConsoleTxt, openPath } from '../../utils/win';

  export default {
    props: ['downCount'],
    data() {
      return {
        isMaskVisible:true,
        dialogVisible:false,
        downTotal: 0,
        errorArray:[],
        iserror: false,//下载是否报错
        downCompelte:[],
        downDrop: {
            dataType: ['二维码图片带群名', '二维码图片','链接']
        },//下拉选项
        downParams: {
            downDic:'',
            downDay:'',
            txtName: '',//txt下载的文件名
            type: 1 //类型 1是下载图片文件名是群名 2是时间戳-索引  3 是文件链接
        },
        isLoad: true,  //加载动画
        defaultTable: []
      };
    },
    computed: {
       ...mapState(['info', 'isStop']), //
    },
    components: {
      loadAction: () => import("../components/load"),
      Radio,
      RadioGroup
    },
    created() {

    },
    activated() {
      this.isLoad =true
      this.isMaskVisible = true
      this.getList();
    },
    methods: {
      currentMethod(index) {
        this.getList(index);
      },
      handleDownload(index,row) {
        if(!row.can_download){
            Message({
                message: `3天前的群二维码可能大部分已过期，因此不提供下载功能。`,
                type: 'warning'
            });
        }
        else {
          if (!this.info.save_address) {
              MessageBox.confirm('您还未设置图片下载位置，请点击确定按钮前往设置！', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then((action) => {
                  if (action == 'confirm') {
                      this.$emit('changeMenu', 'downsetting')
                  }
              })
              return;
          }
          this.iserror = false;
          this.$emit('closeCircle', false)
          this.downTotal = row.personal_download_num + row.enterprise_download_num
          this.downParams.downDay=row.create_time
          this.dialogVisible = true
        }
      },
      //确定下载新方法
      async sureDownMethod() {
          let that = this;
          that.dialogVisible = false;
          that.downPicMethod(that.downParams.type == 3 ? 1 : 0)
      },
      //图片,txt和勾选下载 1txt 0图片带群名 2勾选 3是图片带时间戳-索引
      async downPicMethod(index) {
          let that = this;
          that.$emit('initCount')
          that.downParams.txtName = '';
          //检查创建文件夹
          let address =`${that.info.save_address}`; 
          if (!isDownAddress(address)) {
              Message({
                  message: `下载路径${address}不存在`,
                  type: 'error'
              });
              return;
          }
          address += `\\${that.formatChineseDate(new Date())}`
          if (!isDownAddress(address)) {
              Message({
                  message: `创建保存路径文件夹失败，请检查文件夹权限`,
                  type: 'error'
              });
              return;
          }
          that.downParams.downDic= address

          let grAddress = `${address}\\个人群`;
          if (!isDownAddress(grAddress)) {
              Message({
                  message: `创建保存路径文件夹失败，请检查文件夹权限`,
                  type: 'error'
              });
              return;
          }
          let qyAddress = `${address}\\企业群`;
          if (!isDownAddress(qyAddress)) {
              Message({
                  message: `创建保存路径文件夹失败，请检查文件夹权限`,
                  type: 'error'
              });
              return;
          }
          that.sureDown(index ,0 , 1)
          addConsoleTxt(`下载标题:${that.downParams.title},下载类型：${index == 1 ? '链接' : '图片'}`)
      },
       //定时请求下载
      async sureDown(index, id, count) {
        let that = this;
        if (id < 0 || that.iserror || that.downCount >= that.downTotal) {
              that.$emit('collectMethod', that.iserror ? {
                  isCollect: false,
                  total: that.downTotal,
                  type: 'down',
                  isDown: true
              } : {
                  isCollect: false,
                  type: 'down',
                  errLen: that.errorArray.length
              })
              return;
          }

          addConsoleTxt(`接口请求时间:${nowDataMethod('ms')}`)
          let result = await api.downUserGroup({
              time: that.downParams.downDay,
              id: id,
          })
          addConsoleTxt(`接口请求成功时间:${nowDataMethod('ms')}`)
          let address ='';
          if(result.response.group_type==1){
             address =`${that.downParams.downDic}\\个人群`; 
          }
          else {
            address =`${that.downParams.downDic}\\企业群`; 
          }

          if (result.code == 200) {
              if(result.response.id > 0) {
                  if (index != 1) {
                    that.downParams.txtName = result.response && result.response.name ? (that.downParams.type==1?result.response.name.replace(/[{};',./:"<>?!@#$%^&*_+；‘，。{}|：”“？！@#￥%……&*——+]*/g,''):`${that.downCount+1}${nowDataMethod('noraml')}`) : ''
                    !that.downParams.txtName && (that.downParams.txtName = guid())
                  }
                  if (index == 1 && that.downCount == 0) {
                      that.downParams.txtName = nowDataMethod(true);
                  }
                  if (top.window.require && that.downParams.txtName) {
                      count == 1 && that.$emit('collectMethod', { //先弹出弹窗
                          isCollect: true,
                          count: 1,
                          total: that.downTotal,
                          type: 'down',
                      })
                      try {
                          let downResult = index == 1 ? await appendText(address, that.downParams.txtName + '.txt', result.response.group_qr_code) : await downFileExt(result.response.group_qr_code, address, that.downParams.txtName + '.jpg')
                          if (downResult && downResult.code && (downResult.code == 200 || downResult.code == 202)) {
                              if (downResult.code == 200) {
                                  that.downCompelte.push(result.response.id)
                                  count != 1 && that.$emit('collectMethod', {
                                      isCollect: true,
                                      count: count,
                                      total: that.downTotal,
                                      type: 'down',
                                  })
                                  api.downAfter({ operation_list: [result.response.aunique_identifier] })
                              }
                              downResult.code == 202 && (that.errorArray.push( result.response.id))
                              that.sureDown(index, result.response.id, count++) //202的时候能cout++不
                          }
                          else {
                              that.iserror = true
                              that.sureDown(index, -1, count++)
                          }
                      }
                      catch (err) {
                          if (err && err.code && err.code == 203) {
                              Message({
                                  message: `下载路径不存在~`,
                                  type: 'error'
                              });
                              that.$emit('initCount')
                              that.iserror = true
                              that.sureDown(index, -1,  0)
                          }
                      }
                  }
                  else {
                      that.iserror = true
                      that.sureDown(index, -1,  count++)
                  }
              } else {
                that.sureDown(index, -1,  count++)
              }
          }
          else{
              that.iserror = true
              that.sureDown(index, -1,  count++)
          }
      },
      async getList(data) {
        let that = this;
        that.pageIndex = data ? data : that.pageIndex;
        let result = await api.getDownStatistics({
          page_size: that.pageSize,
          page_index: that.pageIndex, //当前页码
        });
        if (result.code == 200) {
          that.total = result.response.total;
          that.defaultTable =  result.response && result.response.length != 0
              ? result.response.map((item) => {
                  item.create_time = that.formatDate(new Date(item.create_time));
                  item.can_download= that.getDaysBetweenDays(new Date(item.create_time),new Date())<=3
                  return { ...item };
                })
              : [];
              setTimeout(() => {
                this.isMaskVisible = false;
                this.isLoad = false;
              }, 200);
          }
          else {
            setTimeout(() => {
                this.isMaskVisible = false;
                this.isLoad = false;
              }, 200);
          }
      },
      formatChineseDate(date) {
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const seconds = date.getSeconds();
          const formattedDate = `${year}年${month}月${day}日${hours.toString().padStart(2, '0')}时${minutes.toString().padStart(2, '0')}分${seconds.toString().padStart(2, '0')}秒`;
          return formattedDate;
      },
      getDaysBetweenDays(startDate, endDate) {
        var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return diffDays;
      },
      formatDate(date) { //格式化时间
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        return `${year}-${month}-${day}`;
      },
    }
  };
  </script>
  <style lang="less" scoped>
  .inter {
    width: 100%;
    height: 100%;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.98); /* 半透明黑色背景 */
    z-index: 1000; /* 确保它在其他元素之上 */
   }

   .tablePage {
    width: 100%;
    height: 100%;
    /* margin-top:14px; */
    overflow: hidden;
  }

  .tableHeader {
    width: 100%;
    border: 1px solid #DCDCDC;
    border-bottom: 0;

    li {
      line-height: 45px;
      background-image: linear-gradient(#FFFFFF, #F2F3F5, );
      border-right: 1px solid #DCDCDC;
      border-bottom: 1px solid #DCDCDC;
      opacity: 0.9;

      &:after {
        width: 100%;

      }
    }

    li:last-child {
      border-right: 0;
    }
  }

  .tableLi {
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
  }

  .tableList {
    width: 100%;

    li {
      /* flex-shrink:0; */
      line-height: 40px;
      border-right: 1px solid #DCDCDC;
      border-bottom: 1px solid #DCDCDC;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 5px;
      box-sizing: border-box;
    }

    li:last-child {
      border-right: 0;
    }
  }

  /deep/ .el-table__body tr.current-row>td.el-table__cell {
    background-color: #F5F7FA;
  }

  /deep/ .elx-table.elx-editable .elx-body--column {
    height: 25px;
    line-height: 25px;
  }

  /deep/ .elx-table.border--full .elx-header--column {
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;

    div {
      justify-content: center;
    }
  }

  /deep/ .elx-table {
    color: #000;

    .elx-table--body-wrapper {
      overflow-x: hidden;
    }

    .elx-cell {
      text-align: center;
      font-size: 14px;
      user-select: none;
      -webkit-user-select: none;

    }
  }
  .downDialog {
        li {
            padding: 8px 0;
        }

        .downRadio {
            margin: 10px 0;
        }

        .downNow {
            color: #999;
        }

        .downToast {
            color: #FB3838
        }

        .buttonLi {
            padding: 2px 10px;
            border: 1px solid #DCDCDC;
            margin: 0px 5px;
            border-radius: 2px;
            color: #666;
            cursor: pointer;

            &.active {
                border-color: #409EFF;
                background: #409EFF;
                color: #fff;
            }
        }


        /deep/ .el-input {
            width: 160px;
        }

        /deep/ .el-input__inner {
            border-right: 1px solid #DCDCDC;
            border-radius: 2px;
            height: 26px;
        }



        .timePickerComponent {
            width: 50%;
            margin: 10px 0;

            /deep/ .el-input__inner {
                height: 26px;
                line-height: 26px;
            }

            /deep/ .el-range-input {
                line-height: 26px;
                font-size: 12px;
            }

            /deep/ .el-range-separator {
                line-height: 26px;
            }
        }
    }

  /deep/ .el-table {
    color: #000;

    th.gutter {
      display: table-cell !important;
    }

    .cell {
      text-align: center;
      font-size: 13px;
      white-space: nowrap;
      padding: 0px;
      // line-height: 35px;
      box-sizing: border-box;
      // height: 35px;
      user-select: none;
      -webkit-user-select: none;
    }

    .el-table__cell {
      padding: 0;
    }

    .el-table__cell:first-child {
      .cell {
        padding: 0px;
      }
    }

    th.el-table__cell {
      background: linear-gradient(180deg, #FFFFFF, #F2F3F5);

      .cell {
        text-align: center;
        color: #000;
        font-size: 14px;
        padding: 0;
      }
    }

    th.el-table__cell:first-child {
      .cell {
        padding: 0;
      }
    }
  }

  /deep/ .el-loading-spinner {
    i {
      color: #000 !important;
    }

    .el-loading-text {
      color: #000 !important;
    }
  }

  /deep/ .el-table td.el-table__cell {
    border-color: #DCDCDC !important;
  }

  /deep/ .el-table th.el-table__cell.is-leaf {
    border-color: #DCDCDC !important;
  }

  /deep/ .el-table--border .el-table__cell:first-child .cell {
    padding-left: 0px;
  }

  /deep/ .el-loading-spinner {
    .el-loading-text {
      color: #fff;
    }

    i {
      color: #fff;
      font-size: 20px;
    }
  }

  .tableContent {
    height: 100%;
    width: 100%;
  }

  /deep/ .plTableBox {
    height: 100%;
  }

  /deep/ .plTableBox .el-table th {
    padding: 0 !important;
     line-height: 35px;
     height: 35px;
    color: #000;
    background-image: linear-gradient(#e8eaec, #e8eaec), linear-gradient(#e8eaec, #e8eaec);
    background-repeat: no-repeat;
    background-size: 1px 100%, 100% 1px;
    background-position: 100% 0, 100% 100%;
    background-color: #f8f8f9;
    font-weight: 700;
    border-bottom: 0 !important;
  }

  /deep/ .plTableBox .el-table td {
    height: 35px !important;
    line-height: 35px !important;
    color: #000;
    padding: 0 !important;
    font-size: 14px;
  }

  /* 自定义表格 */

  @media (min-width: 1660px) {
    .tableHeader {
      li {
        line-height: 35px;
      }
    }

    .tableList {

      li {
        line-height: 30px;

      }
    }

    /deep/ .el-table {
      .cell {
        font-size: 13px;

        /* -webkit-user-select:none; */
      }

      th.el-table__cell {
        .cell {
          font-size: 13px;
        }
      }
    }
  }
  </style>
  